// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*

PIERIA:
  apiEndPoint: "https://careerapi.champier.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  // enviroment is used for picking the correct image, so its value must be the image name
  // Για το logo πηγαίνουμε στο φάκελο assets στα images και αποθηκερουμε το κατάλληλο logo
  environment: "pieria",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "ΕΠΙΜΕΛΗΤΗΡΙΟ ΠΙΕΡΙΑΣ",
  chamber2: "ΕΠΙΜΕΛΗΤΗΡΙΟΥ ΠΙΕΡΙΑΣ",
  chamberEn: "Chamber of PIERIA",
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ ΠΙΕΡΙΑΣ | Τηλ: +30 2351 023211 | 28ης Οκτωβρίου 9, 60134, Κατερίνη, Ελλάδα, website: www.champier.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF PIERIA | Phone: +30 2351 023211 | 28is Oktovriou 9, Katerini 601 34, Greece, website: www.champier.gr",
  chamberFacebook:"",
  privacymail: "info@champier.gr",
  googleId:
    "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:"G-G60NBP3SWK"


  XALKIDIKI:
  apiEndPoint: "https://careerapi.epihal.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "epihal",
  chamber: "ΕΠΙΜΕΛΗΤΗΡΙΟ ΧΑΛΚΙΔΙΚΗΣ",
  chamber2: "ΕΠΙΜΕΛΗΤΗΡΙΟΥ ΧΑΛΚΙΔΙΚΗΣ",
  chamberEn: "Chamber of XALKIDIKI",
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ ΧΑΛΚΙΔΙΚΗΣ | Τηλ: +30 2371 024200 | Πολυτεχνείου 58 631 00, Ελλάδα, website: www.epihal.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF XALKIDIKI | Phone: +30 2371 024200 | Politechniou 58, Poligiros 631 00, Greece, website: www.epihal.gr",
  chamberFacebook:"",
  privacymail: "info@epihal.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:""

  EVEA:
  apiEndPoint: "https://careerapi.acci.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  // enviroment is used for picking the correct image, so its value must be the image name
  // Για το logo πηγαίνουμε στο φάκελο assets στα images και αποθηκερουμε το κατάλληλο logo
  environment: "ebea",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "ΕΒΕΑ",
  chamber2: "ΕΒΕΑ",
  chamberEn: "ΕΒΕΑ",
  contactData:
    "Στοιχεία επικοινωνίας: Ε.Β.Ε.Α. | Τηλ: +30 210 36.04.815-9, +30 210 36.02.411 | Ακαδημίας 7, 10671, Αθήνα, Ελλάδα, website: www.acci.gr",
  contactDataEn:
    "Contact Data: EBEA | Phone: +30 210 36.04.815-9, +30 210 36.02.411 | Akadimias 7, 10671, Athens, Greece, website: www.acci.gr",
  chamberFacebook:"",
  privacymail: "info@acci.gr",
  googleId:
    "273171360741-jhhdjqtmvk6ka8fnnqpvf6epcr0cvest.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:""


  AITOLOAKARNANIA:
  apiEndPoint: "https://careerapi.epimetol.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "aitol",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "ΕΠΙΜΕΛΗΤΗΡΙΟΥ ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ",
  chamberEn: "Chamber of AITOLOAKARNANIAS",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ | Τηλ: 26410 74500 | Παπαστράτου 53 & Σμρρνης , Αγρίνιο, Ελλάδα, website: www.epimetol.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF AITOLOAKARNANIAS | Phone: 26410 74500 | Papastratou 53 & Smirnis, Agrinio, Greece, website: www.epimetol.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "contact@epimetol.gr",
    googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:""


  KORINTHOS:
  apiEndPoint: "https://careerapi.korinthiacc.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "korinthiac",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Κορινθίας",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητήριο Κορινθίας",
  chamberEn: "Chamber of Korinthias",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Κορινθίας | Τηλ: 27410 24464 |  Ερμορ 2, Τ.Κ. 20131, Κόρινθος, Ελλάδα, website: www.korinthiacc.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Korinthias | Phone: 27410 24464 | Ermou 2, T.K 20131, Korinthos Greece, website: www.korinthiacc.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: " info@korinthiacc.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:"G-MW7JRPYFVQ"

  SAMOS:
  apiEndPoint: "https://careerapi.samoscci.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "samos",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Σάμου",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Σάμου",
  chamberEn: "Chamber of Samos",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Σάμου | Τηλ: 2273087970 |  Π.Κουντουριώτη 19, Τ.Κ. 83100 ΣΑΜΟΣ, Ελλάδα, website: www.samoscci.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Samos | Phone: 2273087970 | P.Kountourioti 19, Τ.Κ. 83100 Samos, website: www.samoscci.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "samcci@otenet.gr",
    googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:"G-DPVRYWGFZP"


  ACHAIA:
  apiEndPoint: "https://careerapi.e-a.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "achaia",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΧΑΪΑΣ",
  chamber2: "ΕΠΙΜΕΛΗΤΗΡΙΟΥ ΑΧΑΪΑΣ",
  chamberEn: "Chamber of AXAIA",
  contactData:
  "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΧΑΪΑΣ | Τηλ: +30 2610 277779 | Αγίου Ανδρέου 89-21, T.K. 26221 Πάτρα, Ελλάδα, website: www.e-a.gr",
  contactDataEn:
  "Contact Data: CHAMBER OF AXAIA | Phone: +30 2610 277779 | Agiou Andreou 89-21, T.K. 26221 Patras, Greece, website: www.e-a.gr",
  chamberFacebook:"",
  privacymail: "ea@e-a.gr",
  googleId:
    "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "720438936924160",
  gtag:"G-RZNTC3QE7F"

  XIOS:
  apiEndPoint: "https://careerapi.chioschamber.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "chios",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Χίου",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Χίου",
  chamberEn: "Chamber of Xios",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Χίου | Τηλ: 2271 1044330 |  Φιλίππου Αργέντη 8, Τ.Κ. 82100 Χίος, Ελλάδα, website: www.chioschamber.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Xios | Phone: 2271 1044330 | Filippou Argenti 8, Τ.Κ. 82100 Xios, Greece, website: www.chioschamber.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "info@chioschamber.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:"G-V2W3RJENGQ"


  Hleia:
  apiEndPoint: "https://careerapi.iliachamber.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "helia",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Ηλείας",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Ηλείας",
  chamberEn: "Chamber of Hleias",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Ηλείας | Τηλ: 2621 034154 |  28ης Οκτωβρίου & Πλ. Ηρώων, Τ.Κ. 27100 Πρργος, Ελλάδα, website: www.iliachamber.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Hleias | Phone: 2621 034154 | 28hs Oktwvriou & PL. Hrwwn, Τ.Κ. 27100 Pyrgos, Greece, website:  www.iliachamber.gr",
  chamberFacebook:"https://www.facebook.com/iliachamber",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "geraniosj@iliachamber.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:"G-DV9WJPB1MK"


  Pella:
  apiEndPoint: "https://careerapi.pellachamber.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "pella",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Πέλλας",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Πέλλας",
  chamberEn: "Chamber of Pella",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Πέλλας | Τηλ: +30 23810 26555-6 |  25ης Μαρτίου 13, Τ.Κ. 58200 Έδεσσα , Ελλάδα, website: www.pellachamber.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Pella | Phone: +30 23810 26555-6 | 25hs Martiou 13, Τ.Κ. 58200 Edessa, Greece, website:  www.pellachamber.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "info@pellachamber.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "720438936924160",
  gtag:"G-G97D6CRWD2"

  Arta:
  apiEndPoint: "https://careerapi.e-artas.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "arta",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Άρτας",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Άρτας",
  chamberEn: "Chamber of Arta",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΡΤΑΣ | Τηλ: +30 26810 28728 |  Κ.Αιτωλού & Ν.Πριοβόλου, Τ.Κ. 47100 Άρτα , Ελλάδα, website: www.e-artas.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF ARTA | Phone: +30 26810 28728 |  K. Aitolou & N.Priovolou, Τ.Κ. 47100 Arta, Greece, website:  www.e-artas.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικού Χαρακτήρα κάθε επιμελητηρίου
  privacymail: "epimelitirio@e-artas.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "720438936924160",
  gtag:"G-WK39GPYL1N"

// DEMO
  apiEndPoint: "http://localhost:8081/backend",
  production: true,
  environment: "helia",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Ηλείας",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Ηλείας",
  chamberEn: "Chamber of Hleias",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Ηλείας | Τηλ: 2621 034154 |  28ης Οκτωβρίου & Πλ. Ηρώων, Τ.Κ. 27100 Πρργος, Ελλάδα, website: www.iliachamber.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Hleias | Τηλ: 2621 034154 | 28hs Oktwvriou & PL. Hrwwn, Τ.Κ. 27100 Pyrgos, Greece, website:  www.iliachamber.gr",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "info@iliachamber.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  chamberFacebook:"",
  gtag:""

Arta:
apiEndPoint: "https://careerapi.e-artas.gr/jobs-0.0.1-SNAPSHOT",
production: true,
environment: "arta",
// Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
chamber: "Επιμελητήριο Άρτας",
//Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
chamber2: "Επιμελητηρίου Άρτας",
chamberEn: "Chamber of Arta",
//Those are the contact data change what is needed
contactData:
"Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ ΑΡΤΑΣ | Τηλ: +30 26810 28728 |  Κ.Αιτωλού & Ν.Πριοβόλου, Τ.Κ. 47100 Άρτα , Ελλάδα, website: www.e-artas.gr",
contactDataEn:
"Contact Data: CHAMBER OF ARTA | Phone: +30 26810 28728 |  K. Aitolou & N.Priovolou, Τ.Κ. 47100 Arta, Greece, website:  www.e-artas.gr",
chamberFacebook:"",
//email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικού Χαρακτήρα κάθε επιμελητηρίου
privacymail: "epimelitirio@e-artas.gr",
googleId:
"288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
facebookId: "720438936924160",

*/
export const environment = {
  apiEndPoint: "https://careerapi.chioschamber.gr/jobs-0.0.1-SNAPSHOT",
  production: true,
  environment: "chios",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Χίου",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Χίου",
  chamberEn: "Chamber of Xios",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: ΕΠΙΜΕΛΗΤΗΡΙΟ Χίου | Τηλ: 2271 1044330 |  Φιλίππου Αργέντη 8, Τ.Κ. 82100 Χίος, Ελλάδα, website: www.chioschamber.gr",
  contactDataEn:
    "Contact Data: CHAMBER OF Xios | Phone: 2271 1044330 | Filippou Argenti 8, Τ.Κ. 82100 Xios, Greece, website: www.chioschamber.gr",
  chamberFacebook:"",
  //email Υπερθυνου για την Προστασία των Δεδομένων Προσωπικορ Χαρακτήρα καθε επιμελητηριου
  privacymail: "info@chioschamber.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
  facebookId: "213717490799431",
  gtag:"G-V2W3RJENGQ"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//import "zone.js/dist/zone-error"; // Included with Angular CLI.
